<template>
  <div class="htmlContent markdown-body" id="markdown_body" v-html="htmlValue">
        
  </div>
</template>

<script>
import api from '@/api/index'
// import mock from './mock'
export default {
  data() {
    return {
      htmlValue: ''
    }
  },
  props:{
    id:{
      type:String
    }
  },
  mounted() {
    if(this.id){
      api.getArticleById({ id: this.id }).then(res => {
        this.htmlValue = res.html
        let desc = 'RCHub是一个免费的人工智能学习社区,为用户提供关于人工智能工具和区块链教程的丰富资源。其中包括ChatGPT,Midjourney的保姆级教程,帮助用户轻松入门和深入了解这些领域的知识。'
        // 动态写入页面描述
        function getDesc(str) {
          let startIndex = str.indexOf('>') // 找到第一个标签的开始下标
          let endIndex = str.indexOf('<',1) // 找到第一个标签的结束下标
          if (startIndex>-1 && endIndex>-1) {
            desc += str.slice(startIndex+1,endIndex)
            if (desc.length<=150) {
              getDesc(str.slice(str.indexOf('>', endIndex)+2))
            }
          }
        }
        getDesc(res.html)
        var metas = document.getElementsByTagName('meta')
        for(let i=0;i<metas.length;i++){
          if (metas[i].name === 'description') {
            metas[i].content = desc
          }
        }
        console.log(desc);
      })
    }
  }
  
}
</script>

<style lang="scss">
.htmlContent{
  flex: 1;
  height: 100%;
  overflow-y: auto;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  user-select: none;
}
</style>